import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { userHasRole, userHasAnyRole } from "../../helpers/GeneralHelper";
import { getUserAvailability, updateUserAvailability } from "../../api/users";
import { getLogoURL } from "../../api/general_api";
import FeatureRouter from "../FeatureRouter/FeatureRouter";
import { GA4Click } from "../Analytics/GA4";
import { MixpanelClick } from "../Analytics/MixPanel";
import useAuth from "../../hooks/useAuth";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Box } from "@mui/material";

const isFranchise = process.env.REACT_APP_IS_FRANCHISE;
const erealtyURL = process.env.REACT_APP_EREALTY_URL;

const NavBar = ({ loggedIn, setLoggedIn }) => {
  const { logout, user } = useAuth();
  const [isAvailable, setIsAvailable] = useState(false);
  const [logoURL, setLogoURL] = useState("");
  const [isChevronUp, setIsChevronUp] = useState(false);
  const [showAgentsDropdown, setShowAgentsDropdown] = useState(false);

  const handleToggleClick = () => {
    setShowAgentsDropdown(!showAgentsDropdown);
    setIsChevronUp(!isChevronUp);
  };

  const event_location_on_screen = "Navbar";

  useEffect(() => {
    setUserAvailability();
    setLogoURL(getLogoURL());
  }, []);

  const setUserAvailability = () => {
    getUserAvailability().then((response) => {
      setIsAvailable(response.data.is_available);
    });
  };

  const toggleUserAvailability = (old_status) => {
    updateUserAvailability(user.crmId, old_status).then((response) => {
      setIsAvailable(response.data.is_available);
    });
  };

  const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME;

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{ backgroundColor: "#0C314E" }}
      variant="dark"
    >
      <Container>
        <Navbar.Brand
          onClick={() => {
            GA4Click("NawyLogo", "Click on Nawy Logo", {
              event_location_on_screen,
            });
            MixpanelClick("NawyLogo", "Click on Nawy Logo", {
              event_location_on_screen,
            });
          }}
          href="/"
        >
          <img src={logoURL} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              onClick={() => {
                GA4Click("NavbarLeads", "Click on Navbar Leads", {
                  event_location_on_screen,
                });
                MixpanelClick("NavbarLeads", "Click on Navbar Leads", {
                  event_location_on_screen,
                });
              }}
              href="/leads"
            >
              Leads
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                GA4Click("NavbarNextActions", "Click on Navbar Next Actions", {
                  event_location_on_screen,
                });
                MixpanelClick(
                  "NavbarNextActions",
                  "Click on Navbar Next Actions",
                  {
                    event_location_on_screen,
                  }
                );
              }}
              href="/nextActions"
            >
              Next Actions
            </Nav.Link>
            <FeatureRouter
              featureKey={"financial_caluclator"}
              element={
                userHasRole("nawy_now") ||
                userHasRole("admin") ||
                userHasRole("sales_manager") ||
                userHasRole("management") ||
                userHasRole("sales_support") ||
                userHasRole("finance") ? (
                  <Nav.Link
                    onClick={() => {
                      GA4Click(
                        "NavbarFinancingCalculator",
                        "Click on Navbar Financing Calculator",
                        { event_location_on_screen }
                      );
                      MixpanelClick(
                        "NavbarFinancingCalculator",
                        "Click on Navbar Financing Calculator",
                        { event_location_on_screen }
                      );
                    }}
                    href="/calculator"
                  >
                    Move Now Calculator
                  </Nav.Link>
                ) : (
                  <></>
                )
              }
            />
            {/* <FeatureRouter
              featureKey={"iscore_calculator"}
              element={
                userHasAnyRole(["finance", "sales_manager", "management"]) ||
                userHasRole("sales_manager") ? (
                  <Nav.Link
                    onClick={() => {
                      GA4Click(
                        "NavbarIscoreCalculator",
                        "Click on Navbar Iscore Calculator",
                        { event_location_on_screen }
                      );
                      MixpanelClick(
                        "NavbarIscoreCalculator",
                        "Click on Navbar Iscore Calculator",
                        { event_location_on_screen }
                      );
                    }}
                    href="/iscoreCalculator"
                  >
                    IScore Calculator
                  </Nav.Link>
                ) : (
                  <></>
                )
              }
            /> */}
            {/* <Nav.Link href="/automaticAlerts">Automatic alerts</Nav.Link> */}
            {COMPANY_NAME !== "nawy" && (
              <Nav.Link
                onClick={() => {
                  GA4Click("NavbarAddLead", "Click on Navbar Add Lead", {
                    event_location_on_screen,
                  });
                  MixpanelClick("NavbarAddLead", "Click on Navbar Add Lead", {
                    event_location_on_screen,
                  });
                }}
                href="/leads/new"
              >
                Add Lead
              </Nav.Link>
            )}
            <Nav.Link
              onClick={() => {
                GA4Click("NavbarSales", "Click on Navbar Sales", {
                  event_location_on_screen,
                });
                MixpanelClick("NavbarSales", "Click on Navbar Sales", {
                  event_location_on_screen,
                });
              }}
              href="/sales"
            >
              Sales
            </Nav.Link>
            <FeatureRouter
              featureKey={"commissions"}
              element={
                <Nav.Link
                  onClick={() => {
                    GA4Click(
                      "NavbarCommissions",
                      "Click on Navbar Commissions",
                      {
                        event_location_on_screen,
                      }
                    );
                    MixpanelClick(
                      "NavbarCommissions",
                      "Click on Navbar Commissions",
                      {
                        event_location_on_screen,
                      }
                    );
                  }}
                  href="/commissions"
                  className="text-center"
                >
                  Commissions
                </Nav.Link>
              }
            />
            <Nav.Link
              onClick={() => {
                GA4Click("NavbarE-Realty", "Click on Navbar E-Realty", {
                  event_location_on_screen,
                });
                MixpanelClick("NavbarE-Realty", "Click on Navbar E-Realty", {
                  event_location_on_screen,
                });
              }}
              href={erealtyURL}
              target="_blank"
            >
              E-Realty
            </Nav.Link>
            <NavDropdown title="More" id="collasible-nav-dropdown1">
              {userHasAnyRole(["sales_manager", "finance", "nawy_assistant"]) ||
              user?.isSupervisor ? (
                <NavDropdown
                  style={{ maxHeight: "40px" }}
                  title={
                    <Box display="flex" alignItems="center">
                      <span
                        style={{
                          color: "black",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        Agents
                        {isChevronUp ? (
                          <ChevronUp
                            style={{
                              width: 16,
                              height: 16,
                              marginLeft: "12px",
                            }}
                          />
                        ) : (
                          <ChevronDown
                            style={{
                              width: 16,
                              height: 16,
                              marginLeft: "12px",
                            }}
                          />
                        )}
                      </span>
                    </Box>
                  }
                  className="text-center agent"
                  show={showAgentsDropdown}
                  onClick={handleToggleClick}
                >
                  <style>
                    {`
                        .agent:hover {
                          background: #F9F9F9;
                        }
                        .agent .dropdown-toggle::after {
                          display: none !important; /* Hide the default dropdown icon */
                        }
                      `}
                  </style>
                  <NavDropdown.Item
                    href={
                      COMPANY_NAME === "nawy"
                        ? `${process.env.REACT_APP_NEW_CRM_URL}/agents-activity`
                        : "agents"
                    }
                    className="text-center"
                  >
                    Agents Activity
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      COMPANY_NAME === "nawy"
                        ? `${process.env.REACT_APP_NEW_CRM_URL}/agents-targets`
                        : "agents"
                    }
                    className="text-center"
                  >
                    Agents Targets
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      COMPANY_NAME === "nawy"
                        ? `${process.env.REACT_APP_NEW_CRM_URL}/auto-delegation-weights`
                        : "agents"
                    }
                    className="text-center"
                  >
                    Auto Delegation Weights
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <></>
              )}
              {userHasRole("sales_manager") ||
              user?.isSupervisor ||
              userHasRole("sales_agent") ? (
                <>
                  <NavDropdown.Item href="/dashboards" className="text-center">
                    Dashboards
                  </NavDropdown.Item>
                </>
              ) : (
                <></>
              )}
              <FeatureRouter
                featureKey={"sales_league"}
                element={
                  userHasRole("sales_manager_temp_team") ||
                  userHasRole("senior_sales_manager") ||
                  userHasRole("sales_director") ||
                  userHasRole("sales_manager") ||
                  userHasRole("admin") ||
                  userHasRole("management") ||
                  userHasRole("sales_admin") ? (
                    <>
                      <NavDropdown.Item href="/league" className="text-center">
                        Sales League
                      </NavDropdown.Item>
                    </>
                  ) : (
                    <></>
                  )
                }
              />

              <NavDropdown.Item
                href={`/nawy-inventory`}
                className="text-center"
              >
                Nawy Inventory
              </NavDropdown.Item>
              <FeatureRouter
                featureKey={"lead-listing"}
                element={
                  <NavDropdown.Item
                    href={`/listing-dashboard`}
                    className="text-center"
                  >
                    Listing Dashboard
                  </NavDropdown.Item>
                }
              />

              <FeatureRouter
                featureKey={"sales-agents-commissions"}
                element={
                  userHasRole("sales_agent") ? (
                    <NavDropdown.Item
                      href="/sales-agents-commissions"
                      className="text-center"
                    >
                      Agents Commissions
                    </NavDropdown.Item>
                  ) : (
                    <></>
                  )
                }
              />

              {userHasRole("sales_manager") || userHasRole("list_uploader") ? (
                <>
                  <NavDropdown.Item href="/UploadLeads" className="text-center">
                    Upload Leads
                  </NavDropdown.Item>
                </>
              ) : (
                <></>
              )}

              {userHasRole("sales_manager") ? (
                <>
                  <NavDropdown.Item
                    href="/marketing-spend"
                    className="text-center"
                  >
                    Marketing Spend
                  </NavDropdown.Item>
                </>
              ) : (
                <></>
              )}

              {userHasAnyRole([
                "sales_manager",
                "finance",
                "nawy_assistant",
              ]) ? (
                <NavDropdown.Item
                  href="/search-by-comments"
                  className="text-center"
                >
                  Search Comments
                </NavDropdown.Item>
              ) : (
                <></>
              )}
              {userHasAnyRole([
                "sales_manager",
                "finance",
                "nawy_assistant",
                "sales_admin",
                "admin",
                "data_entry",
              ]) ? (
                <NavDropdown.Item href="/AgentsTree" className="text-center">
                  Agents Tree
                </NavDropdown.Item>
              ) : (
                <></>
              )}
            </NavDropdown>
            :<></>
            <NavDropdown title="Help" id="collasible-nav-dropdown1">
              <NavDropdown.Item
                href="/user-guide"
                className="text-center"
                onClick={() => {
                  GA4Click("NavbarUserGuide", "Click on Navbar User Guide", {
                    event_location_on_screen,
                  });
                  MixpanelClick(
                    "NavbarUserGuide",
                    "Click on Navbar User Guide",
                    {
                      event_location_on_screen,
                    }
                  );
                }}
              >
                User Guide
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/statuses-glossary"
                className="text-center"
                onClick={() => {
                  GA4Click(
                    "NavbarStatusesGlossary",
                    "Click on Navbar Statuses Glossary",
                    {
                      event_location_on_screen,
                    }
                  );
                  MixpanelClick(
                    "NavbarUserGuide",
                    "Click on Navbar Statuses Glossary",
                    {
                      event_location_on_screen,
                    }
                  );
                }}
              >
                Statuses Glossary
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            {userHasAnyRole([
              "sales_manager",
              "nawy_assistant",
              "digital_marketing",
            ]) ? (
              <NavDropdown title="Settings" id="collasible-nav-dropdown2">
                {userHasAnyRole(["sales_manager"]) && (
                  <>
                    <NavDropdown.Item
                      href="/settings/statuses"
                      className="text-center"
                    >
                      Statuses
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/settings/categories"
                      className="text-center"
                    >
                      Categories
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/settings/pools"
                      className="text-center"
                    >
                      Pools
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/settings/funnels"
                      className="text-center"
                    >
                      Funnels
                    </NavDropdown.Item>
                    {/* <FeatureRouter
                      featureKey={"vacations"}
                      element={
                        <NavDropdown.Item
                          href="/settings/vacations"
                          className="text-center"
                        >
                          Vacations
                        </NavDropdown.Item>
                      }
                    /> */}
                    {/* <FeatureRouter
                      featureKey={"third_party_apps"}
                      element={
                        <NavDropdown.Item
                          href="/settings/thirdPartyApps"
                          className="text-center"
                        >
                          Third party apps
                        </NavDropdown.Item>
                      }
                    /> */}
                    <FeatureRouter
                      featureKey={"lead-icons"}
                      element={
                        userHasRole("sales_manager") ? (
                          <NavDropdown.Item
                            href={`/lead-icons`}
                            className="text-center"
                          >
                            Lead Icons
                          </NavDropdown.Item>
                        ) : (
                          <></>
                        )
                      }
                    />
                    <NavDropdown.Item
                      href="/settings/configurations"
                      className="text-center"
                    >
                      Configurations
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/settings/generalRules"
                      className="text-center"
                    >
                      General Rules
                    </NavDropdown.Item>
                    <FeatureRouter
                      featureKey={"payment_plans"}
                      element={
                        <NavDropdown.Item
                          href="/settings/paymentPlans"
                          className="text-center"
                        >
                          Payment Plans
                        </NavDropdown.Item>
                      }
                    />
                  </>
                )}
                <FeatureRouter
                  featureKey={"linked_locations"}
                  element={
                    userHasAnyRole(["sales_manager", "digital_marketing"]) && (
                      <NavDropdown.Item
                        href="/settings/linkedLocations"
                        className="text-center"
                      >
                        Linked Locations
                      </NavDropdown.Item>
                    )
                  }
                />
                {userHasAnyRole(["sales_manager", "nawy_assistant"]) && (
                  <>
                    <NavDropdown.Item
                      href="/settings/facebookRules"
                      className="text-center"
                    >
                      Facebook Rules
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/settings/users"
                      className="text-center"
                    >
                      Users
                    </NavDropdown.Item>
                  </>
                )}
              </NavDropdown>
            ) : (
              <></>
            )}

            <NavDropdown title={user?.name} id="collasible-nav-dropdown3">
              {isFranchise === "true" && (
                <NavDropdown.Item
                  href="/reset-password"
                  className="text-center"
                >
                  Change password
                </NavDropdown.Item>
              )}
              <div id="available-div">
                <span id="available-span">Available</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isAvailable}
                    onChange={(e) => {
                      GA4Click("NavbarAvailable", "Turn available on/off", {
                        event_location_on_screen,
                      });
                      MixpanelClick(
                        "NavbarAvailable",
                        "Turn available on/off",
                        {
                          event_location_on_screen,
                        }
                      );
                      toggleUserAvailability(e.target.checked);
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={(e) => logout()}
                className="text-center"
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
